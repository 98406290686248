<template>
    <div class="page">
        <Distribution :edit="true"></Distribution>
    </div>
</template>

<script>
import Distribution from "./components/Distribution.vue"

export default {
    components: {
        Distribution,
    },
}
</script>

<style lang="less" scoped>
.page {
    padding: 20px;
}
</style>
